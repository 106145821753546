<template>
  <div class="icon-switch-profile">
    <div @click="handleClick(0)" :class="{ active: currentIndex === 0 }">
      <svg class="icon" viewBox="0 0 1024 1024" width="200" height="200">
        <path
            d="M266.666667 170.666667h490.666666a138.666667 138.666667 0 0 1 138.453334 130.816L896 309.333333v405.333334a138.666667 138.666667 0 0 1-130.816 138.453333L757.333333 853.333333H266.666667a138.666667 138.666667 0 0 1-138.453334-130.816L128 714.666667v-405.333334a138.666667 138.666667 0 0 1 130.816-138.453333L266.666667 170.666667h490.666666-490.666666z m490.666666 64H266.666667a74.666667 74.666667 0 0 0-74.410667 68.522666L192 309.333333v405.333334a74.666667 74.666667 0 0 0 68.522667 74.410666l6.144 0.256h490.666666a74.666667 74.666667 0 0 0 74.410667-68.565333l0.256-6.101333v-405.333334a74.666667 74.666667 0 0 0-68.565333-74.410666L757.333333 234.666667z m-328.405333 174.293333a21.333333 21.333333 0 0 1 25.045333-10.922667l3.584 1.408 186.965334 93.44a21.333333 21.333333 0 0 1 3.242666 36.181334l-3.242666 2.005333-186.965334 93.482667a21.333333 21.333333 0 0 1-30.549333-15.232L426.666667 605.44V418.517333a21.333333 21.333333 0 0 1 2.261333-9.557333z"></path>
      </svg>
    </div>
    <div @click="handleClick(1)" :class="{ active: currentIndex === 1 }">
      <svg class="icon" viewBox="0 0 1024 1024" width="200" height="200">
        <path
            d="M335.008 916.629333c-35.914667 22.314667-82.88 10.773333-104.693333-25.557333a77.333333 77.333333 0 0 1-8.96-57.429333l46.485333-198.24a13.141333 13.141333 0 0 0-4.021333-12.864l-152.16-132.586667c-31.605333-27.52-35.253333-75.648-8.234667-107.733333a75.68 75.68 0 0 1 51.733333-26.752L354.848 339.2c4.352-0.362667 8.245333-3.232 10.026667-7.594667l76.938666-188.170666c16.032-39.2 60.618667-57.92 99.52-41.461334a76.309333 76.309333 0 0 1 40.832 41.461334l76.938667 188.16c1.781333 4.373333 5.674667 7.253333 10.026667 7.605333l199.712 16.277333c41.877333 3.413333 72.885333 40.458667 69.568 82.517334a76.938667 76.938667 0 0 1-26.08 51.978666l-152.16 132.586667c-3.541333 3.082667-5.141333 8.074667-4.021334 12.853333l46.485334 198.24c9.621333 41.013333-15.36 82.336-56.138667 92.224a75.285333 75.285333 0 0 1-57.525333-9.237333l-170.976-106.24a11.296 11.296 0 0 0-12.010667 0l-170.986667 106.24zM551.786667 756.032l170.976 106.24c2.624 1.621333 5.717333 2.122667 8.650666 1.408 6.410667-1.557333 10.56-8.426667 8.928-15.424l-46.485333-198.24a77.141333 77.141333 0 0 1 24.277333-75.733333L870.293333 441.706667c2.485333-2.165333 4.053333-5.312 4.330667-8.746667 0.565333-7.136-4.490667-13.173333-10.976-13.696l-199.712-16.288a75.989333 75.989333 0 0 1-64.064-47.168l-76.938667-188.16a12.309333 12.309333 0 0 0-6.538666-6.741333c-5.898667-2.496-12.725333 0.373333-15.328 6.741333l-76.949334 188.16a75.989333 75.989333 0 0 1-64.064 47.168l-199.701333 16.288a11.68 11.68 0 0 0-7.978667 4.181333 13.226667 13.226667 0 0 0 1.333334 18.261334l152.16 132.586666a77.141333 77.141333 0 0 1 24.277333 75.733334l-46.485333 198.229333a13.333333 13.333333 0 0 0 1.514666 9.877333c3.488 5.792 10.581333 7.530667 16.064 4.128l170.986667-106.229333a75.296 75.296 0 0 1 79.562667 0z"></path>
      </svg>
    </div>
    <div @click="handleClick(2)" :class="{ active: currentIndex === 2 }">
      <svg class="icon" viewBox="0 0 1024 1024" width="200" height="200">
        <path
            d="M332.8 249.6c38.4 0 83.2 19.2 108.8 44.8L467.2 320 512 364.8 556.8 320l25.6-25.6c32-32 70.4-44.8 108.8-44.8 19.2 0 38.4 6.4 57.6 12.8 44.8 25.6 70.4 57.6 76.8 108.8 6.4 44.8-6.4 89.6-38.4 121.6L512 774.4 236.8 492.8C204.8 460.8 185.6 416 192 371.2c6.4-44.8 38.4-83.2 76.8-108.8C288 256 313.6 249.6 332.8 249.6L332.8 249.6M332.8 185.6C300.8 185.6 268.8 192 243.2 204.8 108.8 275.2 89.6 441.6 185.6 537.6l281.6 281.6C480 832 499.2 838.4 512 838.4s32-6.4 38.4-19.2l281.6-281.6c96-96 76.8-262.4-57.6-332.8-25.6-12.8-57.6-19.2-89.6-19.2-57.6 0-115.2 25.6-153.6 64L512 275.2 486.4 249.6C448 211.2 390.4 185.6 332.8 185.6L332.8 185.6z"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchBar",
  data() {
    return {
      currentIndex: 0 // 默认选中第一个
    }
  },
  methods: {
    handleClick(index) {
      this.currentIndex = index;
      this.$emit("switch", index);
    }
  }
}
</script>

<style scoped>
.icon-switch-profile {
  margin: .3rem 1rem;
  display: flex;
  justify-content: space-between;
}

.icon-switch-profile > div {
  flex-grow: 0;
}


.icon-switch-profile div svg {
  width: 32px;
  height: 32px;
  fill: black;
}

.active svg {
  fill: white !important;
  background: #2e2e2e;
  border-radius: 2vw;
}
</style>
